import React, { Component} from 'react';
import store from 'store';
import { Spinner, HttpClient, SimpleForm , AlertsBanner,Dialog,Tooltip } from '@paloaltonetworks/amber-ui';
import { FaDownload, FaQuestionCircle } from "react-icons/fa";
import getConfig from '../../utils/ConfigHelper';
import domainResponseDto from '../../models/DomainResponseDto';
import idpMetadataResponseDto from '../../models/IdpMetadataResponseDto';
import './SSOConfiguration.css';
import pingBackupSPInformationQa from '../../assets/metadata/PingBackupSPInformation-Qa.txt'
import pingBackupSPInformationProd from '../../assets/metadata/PingBackupSPInformation-Prod.txt'


const SSO_CONFIGURATION_GET_API = getConfig('BASE_URL') + getConfig('SSO_CONFIGURATION_GET_API');
const SSO_CONFIGURATION_POST_API = getConfig('BASE_URL') + getConfig('SSO_CONFIGURATION_POST_API');
const SSO_CONFIGURATION_ENABLE_API = getConfig('BASE_URL') + getConfig('SSO_CONFIGURATION_ENABLE_API');
const SSO_CONFIGURATION_DOMAIN_METADATA_API = getConfig('BASE_URL') + getConfig('SSO_CONFIGURATION_DOMAIN_METADATA_API');
const SSO_CONFIGURATION_DOMAIN_ACTIVATE_API= getConfig('BASE_URL') + getConfig('SSO_CONFIGURATION_DOMAIN_ACTIVATE_API');


const IS_BETA = getConfig('IS_BETA');

class SSOConfiguration extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef()

    this.state = {
      spinnerVisible: false,
      domainResponse: domainResponseDto(),
      enableSSOChecked: domainResponseDto()?.isActive,
      activatedInOktaChecked: domainResponseDto()?.activatedInOkta,
      panwInfoPanelVisible: false
    }
  }

  copyToClipboard(element) {
    var r = document.createRange();
    r.selectNode(document.getElementById(element));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    
  }

  testUrl(url) {

      try {
          new URL(url);

          return true;
      } catch (_) {
          return false;
      }
  }
  enableConfiguration=(element)=> {
    this.setState({
      spinnerVisible: true,
     });
      var button = document.getElementById(element);

      const options = {authToken: `abcd`};
      var afterSubmitMessage = "Status successfully ";
     
      const values = {
          identityProviderDomain: store.get('domainName'),
          identityProviderId: document.getElementById('identityProviderId').value,
          identityProviderCertificateText: document.getElementById('identityProviderCertificateText').value,
          identityProviderSSOServiceURL: document.getElementById('identityProviderSSOServiceURL').value,
          identityProviderSSORedirectURL: document.getElementById('identityProviderSSORedirectURL').value,
          enabled: button.checked,
      };
      
      //Submitting SSOR Configuration
      HttpClient.post(SSO_CONFIGURATION_ENABLE_API, values, options).then((response) => {
        if(response.data.data != undefined  &&  response.data.data == true){
            var status = 'enabled';
            if(!values.enabled){
                status = 'disabled';
            }
          
            this.setState({
                submissionStatus: 'success',
                afterSubmitMessage: afterSubmitMessage + status,
                spinnerVisible: false,
            });
        }
        else{
           this.setState({
            submissionStatus: 'error',
            afterSubmitMessage: getConfig('SESSION_EXPIRED_MESSAGE'),
            spinnerVisible: false,
           }); 
        }
        
      }).catch((error) => {

          var errormessage=getConfig('ERROR_MESSAGE');
          if(error.response.status=="400"){
            errormessage=getConfig('BAD_REQUEST_ERROR_MESSAGE');
          }
          
          this.setState((prevState, props) => ({
            submissionStatus: 'error',
            afterSubmitMessage: errormessage,
            spinnerVisible: false
          }));
      });
  
  }

  confirmEnableSSODiaLog=(element)=> {
   
    this.setState({
      afterSubmitMessage: ""
    });

    var currentRef=this;
     var button = document.getElementById(element);
     var status = button.checked ? 'enable' : 'disable';

     var dialogText='All configurations will be saved and user authentication will happen via your Identity Provider’s SSO connection. Please confirm you have verified the SSO setup before enabling.';
     if(status=='disable'){
      dialogText='All configurations will be saved and user authentication will happen via Palo Alto Networks SSO. Are you sure you want to disable it?';
     }
     const dialog= Dialog.confirm({
      
      title: 'Are you sure you want to '+status+' SSO?',
      content: dialogText,
      okText: 'Yes',
      cancelText: 'No',
      
      onOk:async function(values) {
       console.log('save');
        await currentRef.enableConfiguration(element,values);
        dialog.destroy();
       
      },
      onCancel() {
          currentRef.formRef.current.setFieldsValue({enableSSO:currentRef.state.enableSSOChecked})
      }
    });
  }

  activateInOkta=(element)=> {
    this.setState({
      spinnerVisible: true,
     });
      var button = document.getElementById(element);
      var domain=store.get('domainName');
      const options = {authToken: `abcd`};
      var afterSubmitMessage = "Successfully ";
      const values = {
          identityProviderDomain: domain,
          activated: button.checked,
      };
      var url=SSO_CONFIGURATION_DOMAIN_ACTIVATE_API.replace('{domain}',domain);
      

      //Submitting SSOR Configuration
      HttpClient.post(url, values, options).then((response) => {
          var status = 'activated';
          if(!values.activated){
              status = 'deactivated';
          }
          if(response.data.data != undefined){  
              var responseDto=domainResponseDto(response.data.data);
            
                this.setState({
                    submissionStatus: 'success',
                    afterSubmitMessage: afterSubmitMessage + status,
                    spinnerVisible: false,
                    domainResponse: responseDto,
                    panwInfoPanelVisible: responseDto.entityId!=null ,
                    enableSSOChecked:responseDto.isActive,
                    activatedInOktaChecked:responseDto.activatedInOkta
                });
        }
        else{
          this.setState({
            submissionStatus: 'error',
            afterSubmitMessage: getConfig('SESSION_EXPIRED_MESSAGE'),
            spinnerVisible: false,
  
           });
        }
        
      }).catch((error) => {
          
          currentRef.formRef.current.setFieldsValue({activateInOkta:currentRef.state.activatedInOktaChecked});
          this.setState((prevState, props) => ({
            submissionStatus: 'error',
            afterSubmitMessage: getConfig('ERROR_MESSAGE'),
            spinnerVisible: false
          }));
      });
  
  }

  confirmActivateDiaLog=(element)=> {
   
    this.setState({
      afterSubmitMessage: ""
    });

    var currentRef=this;
     var button = document.getElementById(element);
     var status = button.checked ? 'activate' : 'deactivate';

     var contentStr="This will deactivate the Okta configuration and switch to the old setup.";
     var titleStr='Are you sure you want to deactivate SSO configuration?';
     if(button.checked){
      titleStr='Are you sure you want to migrate and activate SSO configuration?';
      contentStr="This will migrate SSO configuration and activate it. Please make sure you have updated the IDP configuration with the new PANW SSO data displayed below.";
     }
     
     const dialog= Dialog.confirm({
      
      title: titleStr,
      content: contentStr,
      okText: 'Save',
      
      onOk:async function(values) {
       console.log('save');
        await currentRef.activateInOkta(element);
        dialog.destroy();
       
      },
      onCancel() {
          currentRef.formRef.current.setFieldsValue({activateInOkta:currentRef.state.activatedInOktaChecked})
      }
    });
  }

  componentDidMount() {
    this.prefetchDomainConfig();
  };

  prefetchDomainConfig(){
      this.setState({
          spinnerVisible: true,
      });
      
    //const options = {authToken: `${authToken}`}
    const options = {authToken: `abcd`}
    // fetching Doamin Configuration if exsists
    HttpClient.get(SSO_CONFIGURATION_GET_API, options).then(response => {
      if(response.status="200"){
       
        var responseDto=domainResponseDto(response.data.data);
        
       this.setState({
           domainResponse: responseDto,
           panwInfoPanelVisible: responseDto.entityId!=null,
          spinnerVisible: false,
          enableSSOChecked:responseDto.isActive,
          activatedInOktaChecked:responseDto.activatedInOkta
       });
     }
    }).catch(error => {
       
        this.setState({
            spinnerVisible: false,
            submissionStatus: 'error',
            afterSubmitMessage: getConfig('ERROR_MESSAGE'),
           
        });
        
    });
  }

  submitForm(values) {
    
    this.setState({
        spinnerVisible: true,
    });

    var responseMessageSuccess = "Configuration saved";

    //const options = {authToken: `${authToken}`}
    const options = {authToken: `abcd`}
    //Submitting SSOR Configuration
    HttpClient.post(SSO_CONFIGURATION_POST_API, values, options).then((response) => {
      
      if(response.data.data != undefined){
        
          var responseDto=domainResponseDto(response.data.data);

          this.setState({
            domainResponse: responseDto,
            submissionStatus: 'success',
            afterSubmitMessage: responseMessageSuccess,
            panwInfoPanelVisible: true,
            enableSSOChecked:responseDto.isActive,
            activatedInOktaChecked:responseDto.activatedInOkta,
            spinnerVisible: false
          });
       }
       else{
         
         this.setState({
          submissionStatus: 'error',
          afterSubmitMessage: getConfig('SESSION_EXPIRED_MESSAGE'),
          spinnerVisible: false,

         }); 
      }
    }).catch((error) => {
        
         var responseMessageError=getConfig('ERROR_MESSAGE');
         if(error.response.status=="400"){
            responseMessageError=getConfig('BAD_REQUEST_ERROR_MESSAGE');
         }

        this.setState({
            submissionStatus: 'error',
            afterSubmitMessage: responseMessageError,
            spinnerVisible: false,

        });
    });

  }
  
  closeModal() {
    this.setState({
        visible: false,
    });
   }

  confirmDiaLog(values){
    this.setState({
      afterSubmitMessage: ""
    });
    const saveValues=values;
    const currentRef=this;

    const dialog= Dialog.confirm({
      
      title: 'Are you sure you want to save the changes?',
      content: 'If SSO is already active, the changes will take effect immediately.',
      okText: 'Save',
      
      onOk:async function(values) {
       console.log('save');
       
        await currentRef.submitForm(saveValues);
        dialog.destroy();
       
      },
      onCancel() {
          console.log('Cancel');
      }
    
  });
  }

  downLoadMetaDataBackup(){
    this.setState({
      spinnerVisible: true,
    });

    var postData = new FormData();
		var xhr = new XMLHttpRequest();

    if(getConfig('ENV')!='prod'){
		   xhr.open('GET',  pingBackupSPInformationQa, true);
    }
    else{
      xhr.open('GET',  pingBackupSPInformationProd, true);
    }
		xhr.responseType = 'blob';
		xhr.onload = function (e) {
			var blob = xhr.response;
      this.downLoadFile(blob,'.txt','PANW-PingBackupSPInformation.txt');
      this.setState({
        spinnerVisible: false
       });

		}.bind(this)
		xhr.send(postData);





   }

  downLoadMetaData(domain,idpId){
    this.setState({
        spinnerVisible: true,
    });
  
    var url=SSO_CONFIGURATION_DOMAIN_METADATA_API.replace('{domain}',domain);
    url=url.replace('{idPId}',idpId);
    
    const options = {authToken: `abcd`}
   
   HttpClient.get(url, options).then(response => {
      if(response.data.data != undefined){
          var responseDto=idpMetadataResponseDto(response.data.data);
          var metadataValue=responseDto.metadata;
          this.downLoadFile(metadataValue,'.xml','metadata.xml');
          this.setState({
            spinnerVisible: false
          });
      }
      else{
        this.setState({
          submissionStatus: 'error',
          afterSubmitMessage: getConfig('SESSION_EXPIRED_MESSAGE'),
          spinnerVisible: false,
         }); 
      }
    }).catch(error => {
      this.setState({
          spinnerVisible: false
      });
      
   });
}


  downLoadFile(value,fileType,fileName) {

    var blob = new Blob([value], { type: fileType });

    var a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function() { URL.revokeObjectURL(a.href); }, 1500); 

}

  render() {
    
  
    const formSectionConfiguration = {
      items: [
        this.state.domainResponse.migratedToOkta ? {
          id: 'messageMigratedToOkta',
          type: 'element',
          label: false,
          element: <div data-show="true" className="message ant-alert ant-alert-warning ant-alert-with-description ant-alert-no-icon amber-alerts-banner">
           <span className="ant-alert-message"></span>
           <span className="ant-alert-description">
           We highly recommend that you take a backup of your existing IDP configuration as it's required for rollback. 
           Click <a href="#" onClick={() => (this.downLoadMetaDataBackup())}>
          here</a> to download.
           </span></div>,
        }:false,
        !this.state.domainResponse.migratedToOkta ? {
          id: 'messageNewIntegration',
          type: 'element',
          label: false,
          element: <div data-show="true" className="message ant-alert  ant-alert-with-description ant-alert-no-icon amber-alerts-banner">
          <span className="ant-alert-description">
           If you have additional domains that needs to be enabled for 3rd party Idp, please open a support case.
           </span></div>,
       }:false,
      {
        id: 'identityProviderId',
        type: 'text',
        disabled: !this.state.domainResponse.enableForm,
        label: <Tooltip title='Issuer URI of the Identity Provider. This value is usually the SAML Metadata EntityID of the IdP EntityDescriptor.'>
          <span className='requiredMark'>*</span>Identity Provider ID <FaQuestionCircle /></Tooltip> ,
        
        placeholder: '',
        defaultValue: this.state.domainResponse.identityProviderId,
        rules: [{ required: true, message: 'Please provide ID for Identity Provider' }],
      },
      !this.state.panwInfoPanelVisible ?
      {
        id: 'identityProviderCertificate',
        type: 'file',
        disabled: !this.state.domainResponse.enableForm,
        label: <Tooltip title='The PEM or DER encoded public key certifi.blueFontcate of the Identity Provider used to verify SAML message and assertion signatures.'> 
           <span className='requiredMark'>*</span>Identity Provider Certificate <FaQuestionCircle /></Tooltip>,
        placeholder: '',
        defaultValue: '',
        description: 'Upload File',
        rules: [{ required: true, message: 'Please provide certificate file' }],
      }
      :
      {
        id: 'identityProviderCertificateText',
        type: 'textarea',
        disabled: !this.state.domainResponse.enableForm,
        label: <Tooltip title='The PEM or DER encoded public key certificate of the Identity Provider used to verify SAML message and assertion signatures.'> 
           <span className='requiredMark'>*</span>Identity Provider Certificate <FaQuestionCircle /></Tooltip>,

        placeholder: '',
        defaultValue: this.state.domainResponse.identityProviderCertificate,
        description: 'Upload File',
        rules: [{ required: true, message: 'Please provide certificate file' }],
      }, 
      {
        id: 'identityProviderSSOServiceURL',
        type: 'text',
        disabled: !this.state.domainResponse.enableForm,
        readOnly: true,
        label: <Tooltip title='The binding-specific IdP Authentication Request Protocol endpoint that receives SAML AuthnRequest messages from Palo Alto Networks.'>
          <span className='requiredMark'>*</span>Identity Provider SSO Service URL <FaQuestionCircle /></Tooltip>,

        placeholder: '',
        defaultValue: this.state.domainResponse.identityProviderSSOServiceURL,
        rules: [ {required: true, validator: (rule, value)=>(this.testUrl(value)),  
          message: 'Please provide valid Service URL'}],
      },
      {
        id: 'identityProviderSSORedirectURL',
        type: 'text',
        disabled: !this.state.domainResponse.enableForm,
        label:<Tooltip title='The value of the destination in the SAML Authentication Request. Leave it empty to exclude it. It will be defaulted to Identity Provider SSO URL in the SAML Request.'>
           Identity Provider Destination URL <FaQuestionCircle />
        </Tooltip> ,

        placeholder: '',
        defaultValue: this.state.domainResponse.identityProviderSSORedirectURL,
          hasFeedback: true,
        rules: [{required: false}],
      },

      //Activate in OKTA
      this.state.domainResponse.migratedToOkta ? {
        id: 'activateOktaMessage',
        type: 'element',
        label: false,
        element: <div data-show="true" className="message ant-alert ant-alert-warning ant-alert-with-description ant-alert-no-icon amber-alerts-banner">
         <span className="ant-alert-message"></span>
         <span className="ant-alert-description">
         Please make sure you update your IDP configuration with the new details displayed below and test the SSO setup. 
         You can test it by visiting the Identity Provider SSO Service URL on an incognito window.
         </span></div>,
      }:false,

     this.state.domainResponse.migratedToOkta ? {
        id: 'activateInOkta',
        type: 'checkbox',
        label: <Tooltip title='This will activate/deactivate SSO configuration in OKTA.'>
              Activate In OKTA <FaQuestionCircle /></Tooltip>,
        placeholder: '',
        defaultValue: this.state.activatedInOktaChecked,
        rules: [{ required: false }],
        onChange: () => (this.confirmActivateDiaLog('activateInOkta')),
     }:false,
     
     //EnableSSO
     this.state.domainResponse.displayEnableSSO ? {
         id: 'enableSSOMessage',
         type: 'element',
         label: false,
         element: <div data-show="true" className="message ant-alert ant-alert-warning ant-alert-with-description ant-alert-no-icon amber-alerts-banner">
          <span className="ant-alert-message"></span>
          <span className="ant-alert-description">
          Please make sure you test the SSO setup before you Enable the Identity Provider. You can test it by visiting the Identity Provider SSO Service URL on an incognito window.
          </span></div>,
       }:false,

       this.state.domainResponse.displayEnableSSO ? {
        id: 'enableSSO',
        type: 'checkbox',
        label: <Tooltip title='This will Enable/disable the Identity Provider. If Enabled, it will redirect 
               all the users except domain admins to the configured identity provider for authentication.'>
              Enable Identity Provider <FaQuestionCircle /></Tooltip>,
        placeholder: '',
        defaultValue: this.state.enableSSOChecked,
        rules: [{ required: false }],
        onChange: () => (this.confirmEnableSSODiaLog('enableSSO')),
      }:false,

      {
          id: 'msgfieldRequired',
          type: 'element',
          label: false,
          element: <span className="button-right"><a className="ant-btn amber-btn simple" href={getConfig('CSP_URL') + getConfig('ADD_USERS_PATH') + this.props.pageMetaData.supportAccountId + getConfig('CONFIG')} target="_blank">Add Users</a><span className="spacer"></span><a  className="ant-btn amber-btn simple" href={getConfig('APP_PORTAL_URL')+getConfig('ROLES_PATH')}  target="_blank">Assign App Roles</a></span>,
      },

      ].filter(Boolean)
      };

    const formSectionPANWInfo = {
      isCollapsible: false,
      heading: 'Palo Alto Networks Service Provider Information',
      items: [
      {
        id: 'msgfieldPANWInfo',
        type: 'element',
        label: false,
        element: <span>Your identity provider may require you to enter information about Palo Alto Networks.</span>,
      },
      {
      id:   'entityId',
      type: 'element',
      label: <Tooltip title='The intended audience of the SAML assertion. This is the same as Entity ID.'>
      ENTITY ID <FaQuestionCircle /></Tooltip>,
      element: <span><div id='entityIdValue'>{this.state.domainResponse.entityId}</div><div className="small-button"><a href="#" onClick={() => (this.copyToClipboard('entityIdValue'))}>Copy</a></div></span>,
      },
      {
      id: 'acsUrl',
      type: 'element',
      label: <Tooltip title='The location where the SAML assertion is sent with a POST operation.'>
              ACS URL <FaQuestionCircle /></Tooltip>,
      element: <span><div id='acsUrlValue'>{this.state.domainResponse.acsUrl}</div><div className="small-button"><a href="#" onClick={() => (this.copyToClipboard('acsUrlValue'))}>Copy</a></div></span>,
      },
      {
      id: 'panwCert',
      type: 'element',
      label: <Tooltip title='Public key certificate you can use to validate the SAML sign-in request (SAML AuthRequest)'>
              CERTIFICATE <FaQuestionCircle /></Tooltip>,
      element: <span><div className="divWrapWithScroll"  id='panwCertValue' >{this.state.domainResponse.panwCert} </div>
      <div><span className="small-button marginTop"><a  href="#" onClick={() => (this.copyToClipboard('panwCertValue'))}>Copy</a></span>
      &nbsp;&nbsp;&nbsp;
      <a href="#" onClick={() => (this.downLoadFile(this.state.domainResponse.panwCert,'.pem','certificate.pem'))}>
          Download Certificate <FaDownload /></a></div>
      </span>
      },
      {
        id: 'metadata',
        type: 'element',
        label: <Tooltip title='The Service provider metadata that contains keys, URLs, and other information necessary for interaction with SAML-enabled identity provider'>
               METADATA <FaQuestionCircle /></Tooltip>,
        element: <span><div>
          <a href="#" onClick={() => (this.downLoadMetaData(store.get('domainName'),this.state.domainResponse.idPConfigurationId))}>
          Download Metadata <FaDownload /></a>
          </div><br/></span>
      }
    ]
    };
    const fromSectionControls = {
      isCollapsible: false,
      items: [

          {
              id: 'emptySection',
              type: 'element',
              label: false,
              element: <span></span>,
          }
      ]
    };

    var sections = [];
    var panwInfoSection=[];
    if(this.state.panwInfoPanelVisible){
      sections = [
         formSectionConfiguration,
      ];

      panwInfoSection=[ formSectionPANWInfo ];
    }
    else {
      sections = [formSectionConfiguration];
      panwInfoSection=[];
    }
    
    var messageElement=<span className="MainDesc">
                        Provide your SAML configuration to allow users to access Palo Alto Networks apps.</span>;

    if(this.state.domainResponse.migratedToOkta){
      messageElement=<span className="blackFont">
                  <b><u>Important:</u></b> Please review the migration 
                  instructions <a href={getConfig('DOCUMENT_LINK')} target="_blank">here</a> before you proceed.
                  </span>;
    }
    else{
       messageElement=<div data-show="true" className="message ant-alert ant-alert-warning ant-alert-with-description ant-alert-no-icon amber-alerts-banner">
       <span className="ant-alert-description">
       <b><u>Important:</u></b> Please review the setup 
                  instructions <a href={getConfig('SETUP_DOCUMENT_LINK')} target="_blank">here</a> before you proceed.
       </span></div>;
    }
    
    return (
      <div className="main-form">
        <br/>
        <h2>Single Sign-On Configuration: {store.get('domainName')}</h2>
        
          {messageElement}<br/>
          
        <Spinner spinning={this.state.spinnerVisible}>
          <React.Fragment>
            <SimpleForm 
                ref={this.formRef}
                sections={sections}
                submitButtonContent={this.state.domainResponse.enableForm ? 'Save':null}
                afterSubmitMessage={this.state.afterSubmitMessage}
                submissionStatus={this.state.submissionStatus}
                onSubmit={values => this.state.domainResponse.enableForm? this.confirmDiaLog(values) : null}
                
            />
            <SimpleForm
              sections={panwInfoSection}
            />
          </React.Fragment>
        </Spinner>
        <br/>
      </div>
    );
  }
}


export default SSOConfiguration;
