import React, { Component } from 'react';
import store from 'store';
import AccountsHeader from '../AccountsHeader/AccountsHeader'
import SSOConfiguration from '../SSOConfiguration/SSOConfiguration'
import './SSO.css';

class SSO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Accounts Configuration",
      userEmail: store.get('userEmail'),
      domainName: store.get('domainName'),
      userFirstName: store.get('userFirstName'),
      userLastName: store.get('userLastName'),
      userCompany: store.get('userCompany'),
      supportAccountId: props.match.params.supportAccountId,
    }
  }

  render() {
    return (
      <div>
        <AccountsHeader pageMetaData={this.state} />
        <div className="form-container">
          <SSOConfiguration pageMetaData={this.state} />
        </div>
      </div>
    );
  }
}

export default SSO;
