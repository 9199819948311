import store from 'store';
import getConfig from '../utils/ConfigHelper';

import {HttpClient} from "@paloaltonetworks/amber-ui";

const LOGOUT_URL =  getConfig('LOGOUT_URL');
const samlLogout=getConfig('BASE_URL')+"/logoutsaml";


export default function Logout() {
  store.set('loggedIn', false);

  HttpClient.get(samlLogout).then(response => {
   
  }).catch(error => {
    
 });


 // console.log('you have been logged out. boo!');
  window.location.href = LOGOUT_URL;
  return null;
}
