import React, { Component } from 'react';
import { Route, Switch, BrowserRouter, withRouter } from 'react-router-dom';
import NotFound from './common/constants/NotFound'
import SSOConfiguration from './components/SSOConfiguration/SSOConfiguration';
import SSO from './components/SSO/SSO';
import handleLogout from './login/Logout';
import login from './login/Login';
import isLoggedIn from './utils/IsLoggedIn';
import './assets/style/main.css';
import getConfig from "./utils/ConfigHelper";
import {HttpClient} from "@paloaltonetworks/amber-ui";

import store from "store";

const AUTH_API =  getConfig('BASE_URL') + getConfig('AUTH_API');
const LOGIN_URL =  getConfig('LOGIN_URL');


class App extends Component {

    constructor(props) {
        super(props);
        store.set('loggedIn', false);
        this.state = {
            loggedIn: false,
            authenticationError: ''
        }
    }

    componentDidMount() {

        console.log("Authenticating...");

        // fetching Doamin Configuration if exsists
        return HttpClient.post(AUTH_API).then(response => {
           
            if(response.data.mail) {
                store.set('userEmail', response.data.mail);
                store.set('domainName', response.data.mail.split("@")[1]);
                store.set('userFirstName', response.data.firstName);
                store.set('userLastName', response.data.lastName);
                store.set('userCompany', response.data.company);
                store.set('loggedIn', true);
                this.setState({
                    loggedIn: true
                });
            } else {
               
                window.location.href = LOGIN_URL + window.location.href ;
                //window.location.href = LOGIN_URL;
            }
        }).catch(error => { 
            
            var errormessage=getConfig('ERROR_MESSAGE');
            
            if(error.response!=null && (error.response.status=="403" || error.response.status=="401") ){
                 errormessage= "You are not authorized to perform this operation, please contact: ssoadmins@paloaltonetworks.com";
            }
           
            this.setState({authenticationError: errormessage});
        });

    };




    render() {
        
        if (store.get('loggedIn')) {
            return (
                <BrowserRouter>
                    <Switch>
                        <Route path="/sso/" exact component={NotFound} />
                        <Route path='/sso/:supportAccountId?' component={SSO} />
                        <Route path='/logout' component={() => handleLogout()}/>
                        <Route path="*" component={NotFound} />
                    </Switch>
                </BrowserRouter>
            );
        }
        else {
            return (<div className='errorFont'>{this.state.authenticationError}</div>);
        }
    }
}

export default withRouter(App);
