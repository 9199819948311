function DomainResponseDto(obj){
    this.identityProviderId = ((obj) ? obj.identityProviderId : null);
    this.identityProviderCertificate = ((obj) ? obj.identityProviderCertificate : null);
    this.identityProviderSSOBaseURL = ((obj) ? obj.identityProviderSSOBaseURL : null);
    this.identityProviderSSOServiceURL = ((obj) ? obj.identityProviderSSOServiceURL : null);
    this.identityProviderSSORedirectURL = ((obj) ? obj.identityProviderSSORedirectURL : null);
    this.entityId = ((obj) ? obj.entityId : null);
    this.acsUrl = ((obj) ? obj.acsUrl : null);
    this.panwCert = ((obj) ? obj.panwCert : null);
    this.supportAccountId =  ((obj) ? obj.supportAccountId : null);
    this.isActive = ((obj) ? obj.isActive : null);
    this.metadata = ((obj) ? obj.metadata : null);
    this.idPConfigurationId=((obj) ? obj.idPConfigurationId : null);
    this.migratedToOkta=((obj) ? obj.migratedToOkta : null);
    this.activatedInOkta=((obj) ? obj.activatedInOkta : null);
    this.enableForm=((obj) ? obj.enableForm : null);
    this.displayEnableSSO=((obj) ? obj.displayEnableSSO : null);
    return this;
}

module.exports = DomainResponseDto;
